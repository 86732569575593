import React, { ReactNode } from "react";

import { gql } from "@apollo/client";

import { UserRolesAtCompany } from "__generated__/globalTypes";
import { GetUserRoleAtCompany, GetUserRoleAtCompanyVariables } from "__generated__/GetUserRoleAtCompany";
import { useRouter } from "next/router";
import useQuery from "hooks/useQuery";

const GET_USER_ROLE_AT_COMPANY = gql`
  query GetUserRoleAtCompany($companyId: String!) {
    userRoleAtCompany(companyId: $companyId)
  }
`;

type RoleGuardRenderProp = (allowed: boolean) => ReactNode;

const checkForRole = (userRole?: Nullable<UserRolesAtCompany>, roleRequired?: UserRolesAtCompany): boolean => {
  if (roleRequired === UserRolesAtCompany.Admin) {
    return userRole === UserRolesAtCompany.Admin;
  }
  if (roleRequired === UserRolesAtCompany.Member) {
    return userRole === UserRolesAtCompany.Admin || userRole === UserRolesAtCompany.Member;
  }
  return true;
};

export interface CompanyRoleRequiredProps {
  roleRequired?: UserRolesAtCompany;
  children: ReactNode | RoleGuardRenderProp;
}

export const CompanyRoleRequired = ({ children, roleRequired }: CompanyRoleRequiredProps) => {
  const companyId = useRouter().query.companyID as Optional<string>;
  const { data, loading } = useQuery<GetUserRoleAtCompany, GetUserRoleAtCompanyVariables>(GET_USER_ROLE_AT_COMPANY, {
    fetchPolicy: "cache-first",
    variables: { companyId: companyId! },
    skip: !companyId,
  });
  const isReady = !loading && !!roleRequired && !!companyId;
  const hasRole = isReady && checkForRole(data?.userRoleAtCompany, roleRequired);
  if (isReady) {
    if (typeof children === "function") {
      return <>{(children as RoleGuardRenderProp)(hasRole)}</>;
    } else if (hasRole) {
      return <>{children}</>;
    }
  }
  return null;
};

/**
 * Hook version
 * Will return TRUE if the user has the role required.
 */
export const useCompanyRoleRequired = (roleRequired: UserRolesAtCompany) => {
  const companyId = useRouter().query.companyID as Optional<string>;
  const { data, loading } = useQuery<GetUserRoleAtCompany, GetUserRoleAtCompanyVariables>(GET_USER_ROLE_AT_COMPANY, {
    fetchPolicy: "cache-first",
    variables: { companyId: companyId! },
    skip: !companyId,
  });
  const isReady = !loading && !!roleRequired && !!companyId;
  const hasRole = isReady && checkForRole(data?.userRoleAtCompany, roleRequired);
  if (isReady && hasRole) {
    return true;
  } else {
    return false;
  }
};
