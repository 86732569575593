/**
 * Context that manages and tracks all the uploading function and state
 */
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Auth, Storage } from "aws-amplify";
import { toS3Path } from "components/forms/formUtils";
import { HasDocTypeFieldsWithName } from "components/documents/documentUtils";
import useUnmount from "hooks/useUnmount";
import { useIngestUploadedDocuments } from "components/ManualUpload/useIngestUploadedDocuments";
import { InputUploadedDocument } from "__generated__/globalTypes";
import { growlSuccess, growlWarning } from "components/common/Growl";
import update from "immutability-helper";
import { useBeforeunload } from "react-beforeunload";
import Router from "next/router";
import { getCompanyIDAndProjectID } from "helpers/utils";
import { notification } from "antd";

// Storing jobs in a HASH MAP, not an array
type ManualUploadJobs = { [key: string]: ManualUploadJob };

export type ManualUploadJobFinishedFile = InputUploadedDocument;

export interface ManualUploadJob {
  key: string; // used for notification.open()
  documentType: HasDocTypeFieldsWithName;
  finishedFiles: ManualUploadJobFinishedFile[];
  totalFiles: number;
  done: boolean;
  closed: boolean;
  failed: boolean;
  errors: string[];
  projectId: string;
  canceled: boolean;
}

interface ManualUploadManagerProps {
  jobs: ManualUploadJobs;
  dropzoneShowOverride: boolean;

  hideJob(key: string): void;

  addUploadJob(files: File[], documentType: HasDocTypeFieldsWithName): string;

  cancelJob(key: string): void;

  setDropzoneShowOverride: Dispatch<SetStateAction<boolean>>;
}

const ManualUploadManager = React.createContext<ManualUploadManagerProps>({
  jobs: {},
  hideJob: () => undefined,
  addUploadJob: () => "",
  cancelJob: () => undefined,
  setDropzoneShowOverride: () => undefined,
  dropzoneShowOverride: false,
});

/**
 * This context handles all the upload job management.
 * IMPLEMENTATION:
 * Upload jobs and their UI notifications must persist throughout the website and any route changes, which is why this context must be on the most top level before the page component.
 * Each job has a unique key and is stored in the jobs hash map which contains everything, EXCEPT files.
 * The file queues are in a Ref to avoid relying on state
 * Jobs will stay there until the user 'closes' them and they are done.
 */
export const ManualUploadManagerProvider = (props: { children: React.ReactNode }) => {
  // Map of a job key to array of files to upload
  // Using a ref here so upload queue doesn't have to rely on state updates
  const { projectID } = getCompanyIDAndProjectID();

  const jobFiles = useRef<Map<string, File[]>>(new Map());
  const isChangingAllowedRoute = useRef<boolean>(false);
  // Everything except the file list for an upload job stored in state
  const [jobs, setJobs] = useState<ManualUploadJobs>({}); // Note - this is a HASH MAP, not an array
  const [newJob, setNewJob] = useState<Nullable<string>>(null);
  const killswitch = useRef<boolean>(false);
  const [identityId, setIdentityId] = useState<string>();
  const { startIngestion } = useIngestUploadedDocuments();
  const [dropzoneShowOverride, setDropzoneShowOverride] = useState<boolean>(false);

  // window.beforeUnload event that warns user if they are leaving DADO
  useBeforeunload((event) => {
    if (!isChangingAllowedRoute.current) {
      for (const job of Object.values(jobs)) {
        if (!job.done) {
          event.preventDefault();
          growlWarning("You have uploads in progress. If you leave or refresh this page your imports will be canceled.");
          break;
        }
      }
    }
  });

  // This silly code detects and flags when the browser is changing INTERNAL url's
  // This flag is needed so we don't show trigger the window.beforeunload event except when they leave dado
  useEffect(() => {
    const routeChangeStart = () => {
      isChangingAllowedRoute.current = true;
    };
    const routeChangeComplete = () => {
      isChangingAllowedRoute.current = false;
    };

    Router.events.on("routeChangeStart", routeChangeStart);
    Router.events.on("routeChangeComplete", routeChangeComplete);

    return () => {
      Router.events.off("routeChangeStart", routeChangeStart);
      Router.events.off("routeChangeComplete", routeChangeComplete);
    };
  }, []);

  // get the user identity every time the project changes
  useEffect(() => {
    Auth.currentCredentials().then((res) => {
      setIdentityId(res.identityId);
    });
  }, [projectID]);

  useUnmount(() => {
    killswitch.current = true; // Escape hatch to avoid memory leak issues
  });

  // Triggers on when creating a new job
  useEffect(() => {
    checkJobs();
  }, [newJob]);

  const checkJob = (jobKey: string) => {
    const job = { ...jobs[jobKey] };
    if (job.closed && job.done) {
      setJobs((prevState) => {
        const updatedJobs = { ...prevState };
        delete updatedJobs[jobKey];
        return updatedJobs;
      });
    } else if (job.done) {
      return;
    } else if (job.finishedFiles.length >= jobFiles.current.get(jobKey)!.length) {
      // job is finished
      setJobs((prevState) => {
        return update(prevState, {
          [jobKey]: {
            done: { $set: true },
            closed: { $set: false },
          },
        });
      });
    } else {
      runUploadJob(jobKey);
    }
  };

  // Checks, cleans up, and even restart job if needed
  const checkJobs = () => {
    for (const jobKey of Object.keys(jobs)) {
      checkJob(jobKey);
    }
  };

  // Create a new upload job
  const addUploadJob = (files: File[], documentType: HasDocTypeFieldsWithName) => {
    const key = uuidv4();
    const newJob: ManualUploadJob = {
      key,
      documentType,
      done: false,
      closed: false,
      finishedFiles: [],
      totalFiles: files.length,
      errors: [],
      failed: false,
      projectId: projectID!,
      canceled: false,
    };
    setJobs((prevState) => {
      return {
        ...prevState,
        [key]: newJob,
      };
    });
    jobFiles.current.set(key, files);
    setNewJob(key);
    return key;
  };

  // Process the import for an individual file
  const processFile = async (
    file: File,
    documentType: HasDocTypeFieldsWithName,
    jobKey: string
  ): Promise<ManualUploadJobFinishedFile> => {
    if (!process.env.STAGE) {
      throw new Error("Configuration error: required env variable STAGE is falsy");
    }
    const uploadKey = `${uuidv4()}/${file.name}`;
    await Storage.put(uploadKey, file, {
      level: "private",
      contentType: file.type,
    }).catch((error) => {
      console.error(error);
      setJobs((prevState) => {
        return update(prevState, {
          [jobKey]: { errors: { $push: [`${file.name} failed to upload.`] } },
        });
      });
      growlWarning(`${file.name} failed to upload.`);
    });
    const finishedFile: ManualUploadJobFinishedFile = {
      name: file.name,
      customTypeId: documentType.customTypeId,
      path: `dado-${process.env.STAGE}-uploaded-documents/` + toS3Path(identityId, uploadKey), // @TODO this bucket and stage should be moved to backend at some point
      type: documentType.documentType,
    };
    startIngestion([finishedFile], jobs[jobKey].projectId).catch(() => {
      setJobs((prevState) => {
        return update(prevState, {
          [jobKey]: { errors: { $push: [`${file.name} failed to ingest.`] } },
        });
      });
    });
    return finishedFile;
  };

  // Process the next import for the job
  const processNextFile = async (jobKey: string): Promise<boolean> => {
    const job = jobs[jobKey];
    const files = jobFiles.current.get(jobKey);
    if (!files) {
      throw new Error("files is undefined");
    }
    if (!files.length) return false;
    const nextFile = files.shift() as File;
    jobFiles.current.set(jobKey, files);
    const finishedFile = await processFile(nextFile, job.documentType, jobKey);
    setJobs((prevState) => {
      const updatedJobs = update(prevState, {
        [jobKey]: { finishedFiles: { $push: [finishedFile] } },
      });
      return updatedJobs;
    });
    return true;
  };

  // Execute an upload job
  const runUploadJob = async (jobKey: string) => {
    if (!(jobKey in jobs)) return;
    await processNextFile(jobKey);
    if (killswitch.current) {
      console.error("Something triggered the kill switch."); // Testing purposes
      growlWarning("Your upload job has been stopped.");
      setJobs((prevState) => {
        return update(prevState, {
          [jobKey]: {
            failed: { $set: true },
            done: { $set: true },
          },
        });
      });
      notification.close(jobKey);
      return;
    } else if (jobs[jobKey].canceled || jobs[jobKey].failed || jobs[jobKey].done) {
      return;
    } else {
      setTimeout(() => checkJob(jobKey), 0);
    }
  };

  // For when user closes the job notification
  const hideJob = (key: string) => {
    setJobs((prevState) => {
      return update(prevState, {
        [key]: { closed: { $set: true } },
      });
    });
    if (!jobs[key].done) {
      growlSuccess("You will be notified when import is complete. Please do not leave the DADO website.");
    }
  };

  // For when user closes the job notification
  const cancelJob = (key: string) => {
    setJobs((prevState) => {
      return update(prevState, {
        [key]: {
          canceled: { $set: true },
          done: { $set: true },
        },
      });
    });
    if (!jobs[key].done) {
      growlSuccess("Import job canceled.");
    }
  };

  const data = {
    jobs,
    addUploadJob,
    hideJob,
    cancelJob,
    setDropzoneShowOverride,
    dropzoneShowOverride,
  };

  return <ManualUploadManager.Provider value={data}>{props.children}</ManualUploadManager.Provider>;
};

export const useManualUploadManager = () => {
  const context = useContext(ManualUploadManager);
  if (context === undefined) {
    throw new Error("useManualUploadManager can only be used inside ManualUploadManagerProvider");
  }
  return context;
};
