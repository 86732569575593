// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const SENTRY_IGNORE_ERRORS = [
  // ignore 401 errors, which are normal when our user is not authenticated.
  "Response not successful: Received status code 401",
  // this error can be safely ignored.  https://stackoverflow.com/a/50387233
  "ResizeObserver loop limit exceeded",
];

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV || "(unknown)", // (unknown) fallback because this otherwise would default to production
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
